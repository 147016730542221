import React, { Component } from 'react';
import { HashRouter, Route, Switch } from 'react-router-dom';


import Layout from "./Layout";
import Login from "./Auth/Login.js"
import Logout from "./Auth/Logout.js"
import RedirectToFodxpert from './RedirectToFodxpert.js';
import  PrivateRoute  from "../components/Auth/PrivateRoute"

class AppContainer extends Component {
  render() {
    return (
      <HashRouter>
        <Switch>
          <Route path="/login" name="Login" component={Login} />
          <Route path="/logout" name="Logout" component={Logout} />
          <Route path="/redirectToFodxpert" name="RedirectToFodxpert" component={RedirectToFodxpert} />
           <PrivateRoute  path="/" component={Layout} />
        </Switch>
      </HashRouter>
    );
  }
}

export default AppContainer;
